<template>
  <section class="section">
    <div class="container">
      <div class="is-flex is-justify-content-end">
        <b-icon
          icon="account-circle"
          size="is-large"
          type="is-primary"
        ></b-icon>
        <p class="is-size-3 ml-3">
          {{ $store.state.users.user }}
        </p>
      </div>
      <LocaleSwitcher></LocaleSwitcher>
      <div class="columns is-centered is-mobile">
        <div class="column is-8-mobile">
          <div class="box has-background-eurotext-light has-text-white-bis">
            <h2
              class="title is-6 has-text-white-bis has-text-weight-bold has-text-centered"
            >
              {{ $t("password_change.change_user_password_title") }}
            </h2>
            <PasswordChangeForm
              @change-password="changeUserPassword"
              label-class="has-text-weight-normal has-text-white-bis"
            ></PasswordChangeForm>

            <b-loading
              :is-full-page="false"
              v-model="isLoadingUserPassword"
            ></b-loading>
          </div>
        </div>
      </div>

      <div class="is-relative is-pulled-right mt-4 is-size-5">
        v{{ version }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import PasswordChangeForm from "@/components/PasswordChangeForm";
import { userService } from "@/services";
export default {
  components: {
    LocaleSwitcher,
    PasswordChangeForm,
  },
  data() {
    return {
      isLoadingUserPassword: false,
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.menu.data.classes,
    }),
  },
  methods: {
    changeUserPassword(oldPassword, newPassword) {
      console.log(oldPassword, newPassword);
      this.isLoadingUserPassword = true;
      userService
        .updateUserPassword(oldPassword, newPassword)
        .then((data) => {
          if (data) {
            this.$buefy.toast.open({
              message: this.$t("messages.password_updated_success"),
              type: "is-success",
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t("error.wrong_password"),
              type: "is-danger",
            });
          }
        })
        .finally(() => (this.isLoadingUserPassword = false));
    },
  },
};
</script>

<style></style>
