<template>
  <b-field :label="$t('change-locale')">
    <b-select v-model="selectedLocale" icon="flag">
      <option v-for="locale in locales" :key="locale" :value="locale">
        {{ locale }}
      </option>
    </b-select>
    <p class="control">
      <b-button class="button is-primary" @click="changeLocale">{{
        $t("buttons.change")
      }}</b-button>
    </p>
  </b-field>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_LOCALES.split(","),
      selectedLocale: this.$i18n.locale,
    };
  },
  methods: {
    changeLocale() {
      this.$i18n.locale = this.selectedLocale;
      window.localStorage.setItem("locale", this.selectedLocale);
      this.$buefy.snackbar.open({
        message: this.$i18n.t("change-locale-success"),
        position: "is-top-right",
        duration: 3000,
      });
    },
  },
};
</script>

<style></style>
